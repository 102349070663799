import React, { useEffect, useState } from 'react';

import { globalHistory } from '@reach/router';

import expand from '../../../static/icons/chat-icon-expand.svg';
import waitForElement from '../../helpers/waitForElement';
import { ChatContainer, ChatImage } from './LiveChat.style';

const ipScapeLiveChatId = 'MjN0dkwvTXlpU0xnTEZldVNVTDVOZz09_frame';

const LiveChat = () => {
  const [isImpactWidgetVisible, setIsImpactWidgetVisible] =
    useState<boolean>(true);

  const [isHome, setIsHome] = useState<boolean>(false);
  const [isPet, setIsPet] = useState<boolean>(false);

  const handleShowImpactLiveChat = () => {
    setIsImpactWidgetVisible(false);
    if (window.zE) {
      window.zE('webWidget', 'show');
      window.zE('webWidget', 'open');
    }
  };

  const checkUrl = () => {
    // List of urls to show motor chat on
    const motorURls = [
      '/car-insurance',
      '/modern-slavery-statement',
      '/motor-docs',
      '/claims/?product=car',
      '/contact/?product=car',
    ];

    // List of urls to show pet chat on
    const petUrls = [
      '/pet-insurance',
      '/claims/?product=pet',
      '/contact/?product=pet',
    ];

    // List of pages to set no chat on
    const blankPages = ['/login', '/terms-and-conditions'];

    // Get query parameters from url and check if they are defining the product type
    const queryParam =
      typeof window !== 'undefined' ? window.location.search : '';
    const isValidProductQuery = [
      '?product=home',
      '?product=car',
      '?product=pet',
    ].includes(queryParam);

    // List of pages to set no chat on unless there is a valid product query parameter
    const partialBlankPages = ['/claims', '/contact'];

    let skipFlag = false;
    let homeFlag = true;

    // Get url pathname
    const url = typeof window !== 'undefined' ? window.location.href : '';
    // Check blank pages
    blankPages.map((blankPagesUrl) => {
      if (url.includes(blankPagesUrl)) {
        window.dataLayer.push({ event: 'setChat', chat: 'none' });

        setIsHome(false);
        setIsPet(false);

        skipFlag = true;
      }
    });

    // Check partially blank pages and set to skip if they don't have a valid product query parameter set
    partialBlankPages.map((partialBlankPagesUrl) => {
      if (url.includes(partialBlankPagesUrl) && !isValidProductQuery) {
        window.dataLayer.push({ event: 'setChat', chat: 'none' });
        setIsHome(false);
        setIsPet(false);

        skipFlag = true;
      }
    });

    // If blank, skip the rest of the function
    if (skipFlag) {
      return;
    }

    // Check if the url needs motor chat
    motorURls.map((motorPagesUrl) => {
      if (url.includes(motorPagesUrl)) {
        window.dataLayer.push({ event: 'setChat', chat: 'motor' });
        homeFlag = false;
        setIsHome(false);
        setIsPet(false);

        waitForElement(ipScapeLiveChatId).then(() => {
          document
            .getElementById(ipScapeLiveChatId)
            ?.classList.add('ipscape-chat-widget');
        });
      }
    });

    // Check if the url needs pet chat
    petUrls.map((petPagesUrl) => {
      if (url.includes(petPagesUrl)) {
        setIsImpactWidgetVisible(true);
        window.dataLayer.push({ event: 'setChat', chat: 'none' });
        homeFlag = false;
        setIsHome(false);
        setIsPet(true);
      }
    });

    // If not a motor or pet, set chat to home
    if (homeFlag) {
      setIsImpactWidgetVisible(true);
      window.dataLayer.push({ event: `setChat`, chat: 'none' });

      setIsPet(false);
      setIsHome(true);
    }

    // hide live chat on home page
    if (window.location.pathname === '/') {
      setIsImpactWidgetVisible(false);
      window.dataLayer.push({ event: 'setChat', chat: 'none' });
    }
  };

  useEffect(() => {
    checkUrl();
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH' || action === 'POP') {
        checkUrl();
      }
    });
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.zE && (isHome || isPet)) {
      const enabledDepartments = isPet
        ? ['pet customer service']
        : ['customer service', 'renewals'];
      const topSuggested = isPet
        ? { labels: ['petlivechatrecommendedarticle'] }
        : { search: 'home insurance' };

      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          chat: {
            departments: { enabled: enabledDepartments },
          },
        },
      });

      window.zE('webWidget', 'helpCenter:setSuggestions', topSuggested);

      window.zE('webWidget', 'hide');
      window.zE('webWidget:on', 'close', () => {
        // @ts-ignore
        window.zE('webWidget', 'hide');
        setIsImpactWidgetVisible(true);
      });
    }
  }, [isHome, isPet]);

  return (
    isImpactWidgetVisible &&
    (isHome || isPet) && (
      <ChatContainer
        onClick={handleShowImpactLiveChat}
        id="zendesk-chat-widget"
      >
        Chat now
        <ChatImage src={expand} alt="expand live chat" />
      </ChatContainer>
    )
  );
};

export default LiveChat;
