import React from 'react';

import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import NewIcon from '../../../static/svgIcons/NewIcon.svg';
import PhoneCircle from '../../../static/svgIcons/PhoneCircle.svg';
import Logo from '../../../static/svgIcons/PolicyExpert_SVG.svg';
import WarningTriangle from '../../../static/svgIcons/WarningTriangle.svg';
import useTempIssuesBanner from '../../hooks/useTempIssuesBanner';
import { headerLinks } from '../pageLayout/pageLinks';
import CtaBar from './CtaBar';
import NavLink from './NavLink';

const TopNav = () => {
  const { isTablet } = useBreakpoint();
  const { pathname } = useLocation();
  const { displayUrls, isEnabled, messageToShow } = useTempIssuesBanner();

  return (
    <header className="z-50 lg:z-2000 relative lg:sticky top-0 bg-white w-full lg:shadow-lg">
      <div className="lg:h-20 md:h-70px h-55px flex flew-row items-center max-w-1390 lg:px-6 mx-auto w-full justify-center">
        <Link to="/">
          <Logo
            className={`md:h-50px h-35px lg:mr-10`}
            alt="Policy Expert Logo"
          />
        </Link>
        {!isTablet && (
          <>
            <div className="flex flex-row items-center mr-auto space-x-10">
              {headerLinks[0].map(({ url, label, isExternal }) => (
                <NavLink
                  key={`${label}_nav_container`}
                  url={url}
                  isExternal={isExternal}
                  className="flex flew-row h-24px items-center"
                >
                  {label === 'Pet' && <NewIcon className="mr-2" />}
                  <div className="h-18px">
                    <p
                      className={`navigation-links text-black font-semibold mb-0 tracking-normal h-18px ${
                        url === pathname ? 'text-pxPurple' : 'text-black'
                      }`}
                    >
                      {' '}
                      {label}
                    </p>
                  </div>
                </NavLink>
              ))}
            </div>
            <div className="flex flex-row items-center space-x-10">
              {headerLinks[1].map(({ url, label, isExternal }) => (
                <NavLink
                  key={`${label}_nav_container`}
                  url={url}
                  isExternal={isExternal}
                  className="flex flew-row h-24px items-center"
                >
                  {label === 'Claims' ? (
                    <WarningTriangle className="mr-2" />
                  ) : (
                    <PhoneCircle className="mr-2" />
                  )}

                  <div className="h-18px">
                    <p
                      className={`navigation-links font-semibold mb-0 tracking-normal ${
                        url === pathname ? 'text-pxPurple' : 'text-black'
                      }`}
                    >
                      {' '}
                      {label}
                    </p>
                  </div>
                </NavLink>
              ))}
              <Link
                to="/login/"
                style={{ width: '87px' }}
                className="log-in-button btn-secondary m-0 cursor-pointer"
              >
                Log in
              </Link>
            </div>
          </>
        )}
      </div>
      {!isTablet && <CtaBar />}
      {(displayUrls.length === 0 || displayUrls.includes(pathname)) &&
        isEnabled && (
          <div className="text-center bg-pxPurple2 p-10px font-campton font-normal">
            {messageToShow}
          </div>
        )}
    </header>
  );
};

export default TopNav;
