import { styled } from 'styled-components';

import { colors } from '../../styles/sharedStyled';

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  width: 40px;
  height: 20px;
  border-width: 1px;
  border-radius: 20px;
  padding: 1px;
  cursor: pointer;
`;

export const ToggleSwitchButton = styled.div<{ $toggle: boolean }>`
  background-color: ${({ $toggle }) =>
    $toggle ? colors.pink2 : colors.charcoal};
  opacity: ${({ $toggle }) => !$toggle && 0.4};
  width: 15px;
  height: 15px;
  border-radius: 20px;
  transform: ${({ $toggle }) => $toggle && 'translateX(20px)'};
  transition-duration: 300ms;
  box-shadow: 0 0 4px rgb(0 0 0 / 30%);
`;
