import React from 'react';

import { ToggleContainer, ToggleSwitchButton } from './ToggleSwitch.style';

type ToggleSwitchProps = {
  toggle: boolean;
  handleToggle: () => void;
};

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  toggle,
  handleToggle,
}) => {
  return (
    <>
      <ToggleContainer onClick={handleToggle}>
        <ToggleSwitchButton data-testid="toggle-switch" $toggle={toggle} />
      </ToggleContainer>
    </>
  );
};

export default ToggleSwitch;
