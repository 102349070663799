import { styled } from 'styled-components';

import { colors, fontFamilyCamptonSemiBold } from '../../styles/sharedStyled';

export const MainContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  background-color: ${colors.purple3};
  display: flex;
  justify-content: center;
  overflow: scroll;
  padding: 55px 0 120px;
`;

export const SubContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 580px;
  justify-content: center;
  align-items: center;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;

  @media (min-width: 768px) {
    padding: 0 70px;
  }
`;

export const MenuHeader = styled.div<{ $hasMarginTop?: boolean }>`
  font-family: ${fontFamilyCamptonSemiBold};
  font-weight: 600;
  color: ${colors.purple7};
  border-bottom-width: 1px;
  border-style: solid;
  border-color: ${colors.purple13};
  height: 38px;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 20px;
  margin-top: ${({ $hasMarginTop }) => $hasMarginTop && '40px'};
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > a:not(:first-child) {
    margin-top: 20px;
  }
`;

export const ProductButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  & > a:not(:first-child) {
    margin-left: 15px;
  }
`;

export const ProductButton = styled.a`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 25px 20px;
  height: 41px;
  width: 100%;
  max-width: 200px;
  font-size: 15px;
  font-family: ${fontFamilyCamptonSemiBold};
  font-weight: 600;
  color: ${colors.white};
  background-color: ${colors.pink2};
  border-width: 2px;
  border-color: ${colors.pink2};
  border-radius: 15px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &:hover {
    color: ${colors.pink2};
    background-color: ${colors.white};
  }
`;
