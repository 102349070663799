import './src/styles/global.css';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';

import React from 'react';

import * as Sentry from '@sentry/gatsby';
import { navigate } from 'gatsby';
import SwiperCore, {
  EffectCoverflow,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper';

import PageLayout from './src/components/pageLayout/PageLayout';
import { CookieModalProvider } from './src/context/CookieModalProvider';
import DisclaimerProvider from './src/context/DisclaimerProvider';
import FormContextProvider from './src/context/FormContextProvider';
import { OpeningHoursProvider } from './src/context/OpeningHoursProvider';
import { PhoneNumbersProvider } from './src/context/PhoneNumbersProvider';
import { ReviewsProvider } from './src/context/ReviewsProvider';

if (
  process.env.GATSBY_SENTRY_DSN &&
  process.env.GATSBY_ENVIRONMENT_NAME &&
  process.env.GATSBY_TRACES_SAMPLE_RATE &&
  process.env.PROFILES_SAMPLE_RATE &&
  process.env.GATSBY_BUILD_NUMBER
) {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    environment: process.env.GATSBY_ENVIRONMENT_NAME,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: parseFloat(process.env.GATSBY_TRACES_SAMPLE_RATE),
    profilesSampleRate: parseFloat(process.env.PROFILES_SAMPLE_RATE),
    release: `${process.env.GATSBY_BUILD_NUMBER}`,
  });
}

SwiperCore.use([Scrollbar]);
SwiperCore.use([Navigation]);
SwiperCore.use([EffectCoverflow]);
SwiperCore.use([Pagination]);

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <svg
        style={{ visibility: 'hidden', position: 'absolute' }}
        width="0"
        height="0"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <defs>
          <filter id="round">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="goo"
            />
            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
          </filter>
        </defs>
      </svg>

      <DisclaimerProvider>
        <ReviewsProvider>
          <PhoneNumbersProvider>
            <OpeningHoursProvider>
              <FormContextProvider>
                <CookieModalProvider>
                  <PageLayout>{element}</PageLayout>
                </CookieModalProvider>
              </FormContextProvider>
            </OpeningHoursProvider>
          </PhoneNumbersProvider>
        </ReviewsProvider>
      </DisclaimerProvider>
    </>
  );
};

export const onRouteUpdate = ({ location }) => {
  if (location.pathname.startsWith('/pet-insurance/renewal-date/')) {
    navigate('https://policyexpert.co.uk/pet-insurance/', { replace: true });
  }
};
